var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"home"},[_c('intro'),_vm._v(" "),_c('what'),_vm._v(" "),_c('section',{staticClass:"quote pt-4"},[_c('b-container',{staticClass:"mb-0"},[_c('b-row',[_c('b-col',[_c('blockquote',{staticClass:"quack blockquote text-right",staticStyle:{"display":"none"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.clist[0]'))}}),_vm._v(" "),_c('footer',{staticClass:"blockquote-footer"},[_c('a',{attrs:{"href":_vm.$t('lang') === 'fr'
                  ? _vm.$t('link.fr.collectivisons')
                  : _vm.$t('link.en.collectivisons')},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.cblog'))}})])]),_vm._v(" "),_c('blockquote',{staticClass:"blockquote text-right"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.list[0]'))}}),_vm._v(" "),_c('footer',{staticClass:"blockquote-footer"},[_c('a',{attrs:{"href":_vm.$t('lang') === 'fr'
                  ? _vm.$t('link.fr.collectivisons')
                  : _vm.$t('link.en.collectivisons')},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.blog'))}})])])])],1)],1)],1),_vm._v(" "),_c('how'),_vm._v(" "),_c('section',{staticClass:"quote pt-4"},[_c('b-container',{staticClass:"mb-0"},[_c('b-row',[_c('b-col',[_c('blockquote',{staticClass:"quack blockquote text-right",staticStyle:{"display":"none"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.clist[1]'))}}),_vm._v(" "),_c('footer',{staticClass:"blockquote-footer"},[_c('a',{attrs:{"href":_vm.$t('lang') === 'fr'
                  ? _vm.$t('link.fr.convivialisons')
                  : _vm.$t('link.en.convivialisons')},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.cblog'))}})])]),_vm._v(" "),_c('blockquote',{staticClass:"blockquote text-right"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.list[1]'))}}),_vm._v(" "),_c('footer',{staticClass:"blockquote-footer"},[_c('a',{attrs:{"href":_vm.$t('lang') === 'fr'
                  ? _vm.$t('link.fr.convivialisons')
                  : _vm.$t('link.en.convivialisons')},domProps:{"innerHTML":_vm._s(_vm.$t('c2022.why.blog'))}})])])])],1)],1)],1),_vm._v(" "),_c('campaign'),_vm._v(" "),(/^fr/.test(this.$t('lang')))?_c('f-news'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }