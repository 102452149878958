<template>
  <main>
    <section class="py-4">
      <b-container
        id="f-page"
      >
        <h1 class="text-center">
          <span>Partenaires</span>
        </h1>
        <b-row align-h="center">
          <b-col
            lg="8"
            xl="9"
          >
            <p>
              Framasoft est avant tout une communauté d’utilisateurs.
              Cependant, son fonctionnement est clairement celui d’un réseau, non seulement
              de projets, mais aussi de partenaires.
            </p>
            <p>
              Ainsi, Framasoft bénéficie du soutien de nombreuses organisations
              (publiques, commerciales, associatives) qui permettent aux projets de
              croître et de se développer.
            </p>
            <p>
              Si échange il y a entre Framasoft et ces partenaires, ce serait donc
              plutôt du côté de l’amitié, de la reconnaissance, de la confiance
              mutuelle, et du sentiment d’avancer ensemble dans la même direction.
            </p>
            <ol>
              <li><a href="#institutionnels">Partenaires institutionnels</a></li>
              <li><a href="#associations">Associations</a></li>
              <li><a href="#communautes">Communautés et réseaux</a></li>
            </ol>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section
      id="institutionnels"
      class="py-4"
    >
      <b-container>
        <h1 class="text-center mt-4 mb-5">
          Partenaires institutionnels
        </h1>
        <partner
          v-for="partner in institutions"
          :key="partner"
          :partner="partner"
        />
      </b-container>
    </section>

    <section
      id="associations"
      class="py-4"
    >
      <b-container>
        <h1 class="text-center mt-4 mb-5">
          Associations
        </h1>
        <partner
          v-for="partner in associations"
          :key="partner"
          :partner="partner"
        />
      </b-container>
    </section>

    <section
      id="communautes"
      class="py-4"
    >
      <b-container>
        <h1 class="text-center mt-4 mb-5">
          Communautés et réseaux
        </h1>
        <partner
          v-for="partner in communities"
          :key="partner"
          :partner="partner"
        />
      </b-container>
    </section>
  </main>
</template>
<script>
import Partner from '../components/Partner.vue';

export default {
  components: {
    Partner,
  },

  computed: {
    institutions() {
      return Object
        .keys(this.$t('partners'))
        .filter(partner => this.$t(`partners.${partner}.type`) === 'institution');
    },

    associations() {
      return Object
        .keys(this.$t('partners'))
        .filter(partner => this.$t(`partners.${partner}.type`) === 'association');
    },

    communities() {
      return Object
        .keys(this.$t('partners'))
        .filter(partner => this.$t(`partners.${partner}.type`) === 'community');
    },
  },
};
</script>

<style lang="scss">
#partenaires {
  section {
    position: relative;
  }

  main img {
    object-fit: scale-down;
    background: #fff;
    border-radius: 100px;
    width: 200px;
    height: 200px;
  }

  h1, h2 { font-weight: normal; }

  #institutionnels {
    background: var(--f-g2);

    img { border: 5px solid var(--f-g3); }
    h1 {
      color: var(--f-g8);

      &::after {
        border: 3px solid var(--f-g8);
      }
    }
    h2 a { color: var(--f-g7); }

    .row {
      border-top: 1px solid;
      border-image: radial-gradient(var(--f-g7), transparent) 1;
    }
  }

  #associations {
    background: var(--f-b2);

    img { border: 5px solid var(--f-b3); }
    h1 {
      color: var(--f-b8);

      &::after {
        border: 3px solid var(--f-b8);
      }
    }
    h2 a { color: var(--f-b7); }

    .row {
      border-top: 1px solid;
      border-image: radial-gradient(var(--f-b7), transparent) 1;
    }
  }

  #communautes {
    background: var(--f-f2);

    img {  border: 5px solid var(--f-f3); }
    h1 {
      color: var(--f-f9);

      &::after {
        border: 3px solid var(--f-f9);
      }
    }
    h2 a { color: var(--f-f8); }

    .row {
      border-top: 1px solid;
      border-image: radial-gradient(var(--f-f8), transparent) 1;
    }
  }
}

small.date-ajout {
  display: none; // Don't show when they were added for now
}
</style>
