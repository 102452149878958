<template>
  <section id="h-events">
    <b-container
      v-show="Object.keys(events.days).length > 0"
      class="my-5 px-md-0 py-4"
    >
      <h2 v-html="$t('home.events.title')"></h2>
      <h3 v-html="$t('home.events.subtitle')"></h3>

      <b-row
        align-h="around"
        class="pt-4 pb-5"
      >
        <b-col
          v-for="day in events.days.slice(0, 6)"
          :key="day"
          class="py-3"
          md="6"
          xl="4"
        >
          <b-card
            class="overflow-hidden h-100"
            no-body
          >
            <b-row
              class="h-100"
              no-gutters
            >
              <b-col class="h-100">
                <b-card-body
                  class="d-flex flex-column h-100"
                  :title="events[day].title"
                >
                  <time
                    class="d-flex flex-column shadow-sm rounded text-uppercase text-center"
                    :datetime="events[day].date"
                  >
                    <small class="bg-danger rounded-top text-white py-1">
                      {{ new Intl.DateTimeFormat($t('lang'), { month: "short" })
                        .format(new Date(events[day].date)) }}
                    </small>
                    <span class="h4 bg-white rounded-bottom pb-1 px-2 m-0">
                      {{ day.split('-')[2] }}
                    </span>
                  </time>
                  <div
                    class="card-text"
                    v-html="events[day].desc"
                  ></div>
                  <b-card-text class="text-right">
                    <b-button
                      :href="events[day].url"
                      variant="outline-warning btn-sm"
                    >
                      <span v-html="$t('home.events.btn')"></span>
                      <i class="fas fa-chevron-right"></i>
                    </b-button>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          class="py-4"
          cols="12"
          md="8"
          lg="6"
          xl="5"
        >
          <b-card
            class="overflow-hidden h-100 bg-transparent p-0"
            no-body
          >
            <b-row
              class="h-100"
              no-gutters
            >
              <b-col class="h-100">
                <b-card-body class="h-100 d-flex align-items-center justify-content-center p-0">
                  <a
                    class="btn btn-sm btn-outline-warning d-flex align-items-center
                      justify-content-center text-center p-4"
                    :href="$t('link.fmbz')"
                  >
                    <img
                      alt=""
                      style="max-width: 96px;"
                      :src="`${$t('baseurl')}/img/rose.png`"
                    />
                    <p
                      class="my-4"
                    >
                      <span v-html="$t('home.events.all')"></span>
                      <i class="fas fa-chevron-right"></i>
                    </p>
                  </a>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      events: {
        days: [],
      },
    };
  },

  mounted() {
    this.importEvents(1);
  },

  methods: {
    importEvents(page) {
      fetch(`https://mobilizon.fr/@framasoft/outbox?page=${page}`)
        .then(res => res.json())
        .then((data) => {
          data.orderedItems.forEach((item) => {
            if (item.object.startTime !== undefined
              && this.is.before(item.object.startTime.split('T')[0])) {
              const key = `${item.object.startTime.split('T')[0]}-${item.object.uuid}`;
              this.events[key] = {
                url: item.object.id,
                title: item.object.name,
                desc: item.object.content,
                // img: item.object.attachment[0].url,
                // location: item.object.location.address.addressLocality,
                date: item.object.startTime,
              };
              this.events.days.push(key);
            }
          });
          this.events.days.sort();

          if (data.next && this.events.days.length <= 3) {
            this.importEvents(page + 1);
          }
        })
        .catch((err) => { console.error(err) }); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
#h-events {
  background: var(--f-g4);

  h2 {
    margin: -8rem auto 6rem 0;
  }

  h3 {
    color: var(--f-g10);
    font-family: 'Tovari Sans', sans-serif;
    font-size: 3rem;
  }

  .card {
    border: none;
    background: var(--f-g1);
  }

  .card-body {
    padding: 1rem;
  }

  .card-title {
    color: var(--f-g9);
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.75rem;
    max-width: calc(100% - 4rem);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  time {
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 3rem;
  }

  div.card-text {
    font-size: .875rem;
    max-height: 80px;
    overflow: hidden;
  }

  .card-text {
    blockquote {
      padding: 0;
      border: none;

      &::before {
        background: none;
      }
    }
  }

  p.card-text {
    border-top: 1px solid var(--f-g3);
    margin-top: .5rem;
    margin-bottom: -.5rem;

    .btn {
      margin-top: .5rem;
      margin-right: -.5rem;
    }
  }
}
</style>
