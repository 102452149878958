<template>
  <main>
    <b-container id="f-page">
      <b-row>
        <h1 class="col-12 mb-0 text-center">
          <span v-html="$t('graphics.title')"></span>
        </h1>
        <b-col>
          <Logo />

          <Charter />
        </b-col>
      </b-row>
    </b-container>
    <b-container id="f-page">
      <b-row>
        <h1
          id="tools"
          class="col-12 mb-0 text-center"
        >
          <span>
            Outils
          </span>
        </h1>
        <b-col>
          <b-tabs content-class="bg-white px-4 py-1 border border-top-0">
            <b-tab
              active
              title="Palette"
            >
              <Colors />
            </b-tab>
            <b-tab title="Icônes">
              <h2 class="mt-4">
                Icônes
              </h2>
              <h3>Icônes génériques (120px et 32px)</h3>
              <ul class="list-inline">
                <li
                  v-for="img in ['violet', 'orange']"
                  :key="img"
                  class="list-inline-item mr-5 my-2"
                >
                  <img
                    alt=""
                    class="mr-5"
                    :src="`${$t('/')}img/apple-${img}.png`"
                    width="120"
                    height="120"
                  />
                  <img
                    alt=""
                    class="mr-5"
                    :src="`${$t('/')}img/favicon-${img}.png`"
                  />
                </li>
              </ul>
              <Generator />
            </b-tab>
            <b-tab title="Bootstrap">
              <Bootstrap />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import Logo from '../../commons/components/graphics/Logo.vue';
import Charter from '../../commons/components/graphics/Charter.vue';

import Colors from '../../commons/components/graphics/Colors.vue';
import Generator from '../../commons/components/graphics/Generator.vue';
import Bootstrap from '../../commons/components/graphics/Bootstrap.vue';

export default {
  components: {
    Logo,
    Charter,
    Colors,
    Generator,
    Bootstrap,
  },
};
</script>

<style lang="scss">
#graphics #f-page {
  .table {
    background: #fff;
  }

  .card.bg-light {
    background: var(--f-g1) !important;
  }

  .alert a {
    color: inherit;
  }
}
</style>
