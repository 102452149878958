<template>
  <main>
    <intro />
    <prez />
    <known-for />
    <actions />
    <fuel />
    <politic />
    <clarification />
    <events />

    <sfs />
  </main>
</template>

<script>
import Sfs from '../../commons/components/sfs/Main.vue';

import Intro from './home/Intro.vue';
import Prez from './home/Prez.vue';
import KnownFor from './home/KnownFor.vue';
import Actions from './home/Actions.vue';
import Fuel from './home/Fuel.vue';
import Politic from './home/Politic.vue';
import Clarification from './home/Clarification.vue';
import Events from './home/Events.vue';

export default {
  components: {
    Intro,
    Prez,
    KnownFor,
    Actions,
    Fuel,
    Politic,
    Clarification,
    Events,
    Sfs,
  },
};
</script>

<style lang="scss">
html {
  position: relative;
  background: var(--f-g3);
}

body {
  background: var(--f-g3);

  &::before {
    background: linear-gradient(-170deg,#5f2d76,#7dafb6);
    background:
      var(--f-f8)
      url('../../public/img/bg-sky.jpg')
      no-repeat
      center;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#f-page {
  background: var(--f-g2);
  border-radius: 1.75rem;
  padding: 1.75rem;
  margin: 5rem auto;
  position: relative;
  z-index: 1;

  h1 {
    margin: -4rem auto 3rem 1rem;
    transform: rotate(-2deg);

    span {
      display: inline-block;
      background: var(--f-o6);
      padding: 1.25rem 1.75rem .5rem;
      border-radius: .5rem;
      color: #fff;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      font-family: 'Tovari Sans', sans-serif;
      text-transform: uppercase;
      line-height: 2.5rem;
      font-style: normal;
    }
  }

  h2 {
    color: var(--f-g10);
    font-family: 'Tovari Sans', sans-serif;
    font-size: 2.4rem;
    @media (min-width: 576px) {
      font-size: 3rem;
    }
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }

  .mx-auto.col-lg-9 h2 {
    margin-top: 3rem;
  }
}

#home::before {
  content: none;
}

#home #app {
  > #f-header { display: none; }

  h2 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans', sans-serif;
    font-size: 2.4rem;
    @media (min-width: 576px) {
      font-size: 3rem;
    }
    font-style: normal;
    line-height: 2.5rem;
    padding: 1.25rem 1.75rem .5rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;
  }

  main > section {
    padding: 2rem 0;
    position: relative;
  }

  .btn-outline-warning {
    font-weight: bold;
    border: none;
    background: #fff;

    &:hover, &:focus, &:active {
      background: var(--f-o7);
      color: #fff;
    }
  }

  #support {
    margin-top: 1rem;

    @media (min-width: 992px) { /* cat is hidden by the form below 992px */
      background:
        transparent
        url('../../public/img/gattino-galileo.png')
        no-repeat
        10% calc(100% + 40px);
    }

    h2 {
      margin: -6rem auto 2rem 0;
      @media (min-width: 992px) {
        margin: -7rem auto 2rem 0;
      }
    }

    h3 {
      color: var(--f-g10);
    }

    .col-lg-7 .ombre {
      @media (min-width: 992px) {
        margin-top: -4rem;
      }
    }
  }
}

#home {
  #f-i18n {
    --f-secondary: var(--f-primary);
    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .btn-outline-secondary:not(:disabled):not(.disabled).active,
    &.show > .btn-outline-secondary.dropdown-toggle {
      color: #fff;
      background-color: var(--f-secondary);
      border-color: var(--f-secondary);
    }
  }

  #actions {
    h1 {
      background: var(--f-o6);
      border-radius: .5rem;
      color: #fff;
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      font-style: normal;
      line-height: 2.5rem;
      padding: 1.25rem 1.75rem .5rem;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(-2deg);
      width: fit-content;
      margin: -5rem auto 3rem 0 !important;
    }

    h2 {
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      line-height: 2.5rem;
      margin-bottom: 1rem;
      text-transform: none;
      transform: none;
    }
  }

  #lastyear {
    h2 {
      margin: -5rem auto 4rem 0;
    }

    h3 {
      color: var(--f-g10);
      font-family: 'Tovari Sans', sans-serif;
      font-size: 2.4rem;
      @media (min-width: 576px) {
        font-size: 3rem;
      }
      line-height: 2.5rem;
      margin-bottom: 1rem;
    }
  }
}

#educ-pop, #empowerment, #archipelago {
  #f-page {
    h2 {
      margin-top: 2.5rem;
    }

    ul {
      list-style: none;
      padding: 0;

      a { font-weight: bold; }
    }
  }
}
</style>
