<template>
  <div
    :id="`flip-card-${card}`"
    :ref="`flip-card-${card}`"
    class="flip-card mx-auto"
  >
    <div class="inner">
      <div class="front">
        <div
          class="layer-bg-img"
        >
          <div
            :style="`background-image: url('${$t('baseurl')
            }img/cards/${card}.jpg');`"
          ></div>
        </div>

        <div class="layer-frame"></div>

        <span
          class="layer-content"
        >
          <h3 v-html="$t(`fcards.${card}`)"></h3>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.flip-card {
  background-color: transparent;
  width: 235px;
  height: 380px;
  padding: 20px 0; /* keep shadow & card rotation visible */

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  // &:focus-within .inner,
  &.showback .inner {
    transform: rotateY(180deg);
  }

  .front {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    box-shadow: 0px 9px 15px -10px rgba(0, 0, 0, 0.7);
    border-radius: 4px;
  }

  .front {
    // cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, var(--f-f7), var(--f-f8));
      border-radius: 4px;
    }

    &::after { /* fake layer-frame */
      background: #efe3cc;
      content: '';
      display: block;
      position: absolute;
      height: 80px;
      width: 205px;
      bottom: 30px;
      left: calc(50% - 205px / 2);
    }

    > * {
      position: absolute;
    }

    .layer-bg-img,
    .layer-frame,
    .layer-content {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .layer-bg-img {
      width: calc(100% - 30px);
      height: calc(100% - 145px);
      margin: 21px 15px;
      z-index: 0;
      transition: transform .5s ease-in-out;

      > div {
        background: var(--f-g3);
        border-radius: 20px 20px 0 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .layer-frame {
      background-image: url('../../public/img/cards/card-frame.png');
      z-index: 1;
    }

    .layer-bg-btn {
      z-index: 2;
    }

    .layer-content {
      display: flex;
      padding: 0;
      z-index: 3;

      h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--f-f9);
        min-height: 74px;
        width: 100%;
        margin-bottom: 35px;
        margin-top: auto;
        padding: 0 30px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
