<template>
  <header id="f-header">
    <b-container class="mt-4 px-0">
      <b-row class="mx-0 align-items-end justify-content-between">
        <b-col
          class="pl-0"
          lg="8"
        >
          <router-link :to="`/${$t('lang')}`">
            <h1 class="d-flex align-items-center">
              <img
                alt=""
                :src="`${$t('baseurl')}img/logo-white.svg`"
              />
              <span
                class="ml-sm-2 mt-sm-2"
                v-html="$t('color.soft')"
              ></span>
            </h1>
          </router-link>
          <b-nav
            id="f-menu"
            class="rounded"
            pills
          >
            <b-nav-item-dd
              :text="$t('home.menu.actions')"
              toggle-class="nav-link-custom"
            >
              <b-dd-item :to="`/${$t('lang')}/educ-pop`">
                <span v-html="$t('home.menu.educ-pop')"></span>
              </b-dd-item>
              <b-dd-item :to="`/${$t('lang')}/empowerment`">
                <span v-html="$t('home.menu.empowerment')"></span>
              </b-dd-item>
              <b-dd-item :to="`/${$t('lang')}/archipelago`">
                <span v-html="$t('home.menu.archipelago')"></span>
              </b-dd-item>
              <b-dd-item href="https://framasoft.org/roadmap">
                <span v-html="$t('home.menu.roadmap')"></span>
              </b-dd-item>
            </b-nav-item-dd>

            <b-nav-item :href="$t('link.dio')">
              <span v-html="$t('home.menu.services')"></span>
            </b-nav-item>

            <b-nav-item :href="$t('link.blog')">
              <span v-html="$t('txt.blog')"></span>
            </b-nav-item>

            <b-nav-item-dd
              :text="$t('home.menu.findus')"
              toggle-class="nav-link-custom"
            >
              <b-dd-item :href="$t('link.contact')">
                <i class="far fa-fw fa-envelope"></i>
                <span v-html="$t('home.menu.contact')"></span>
              </b-dd-item>
              <b-dd-divider />
              <b-dd-item :href="$t('link.mastodon')">
                <i class="fab fa-fw fa-mastodon"></i>
                <span v-html="$t('home.menu.mastodon')"></span>
              </b-dd-item>
              <b-dd-item :href="$t('link.fpt')">
                <i class="fac fa-fw fa-peertube"></i>
                <span v-html="$t('home.menu.peertube')"></span>
              </b-dd-item>
              <b-dd-item :href="$t('link.fmbz')">
                <i class="fac fa-fw fa-mobilizon"></i>
                <span v-html="$t('home.menu.mobilizon')"></span>
              </b-dd-item>
              <b-dd-item :href="$t('link.rss')">
                <i class="fas fa-fw fa-rss"></i>
                <span v-html="$t('home.menu.rss')"></span>
              </b-dd-item>
              <b-dd-item :href="$t('link.newsletter')">
                <i class="fas fa-fw fa-envelope"></i>
                <span v-html="$t('home.menu.newsletter')"></span>
              </b-dd-item>
              <b-dd-divider />
              <b-dd-item :href="$t('link.twitter')">
                <i class="fab fa-fw fa-twitter fa-flip-vertical"></i>
                <span v-html="$t('txt.twitter')"></span>
              </b-dd-item>
              <b-dd-item :href="$t('link.facebook')">
                <i class="fab fa-fw fa-facebook-f"></i>
                <span v-html="$t('txt.facebook')"></span>
              </b-dd-item>
            </b-nav-item-dd>
            <li class="nav-item">
              <I18n />
            </li>
          </b-nav>
        </b-col>
        <b-col
          class="mx-auto mr-md-n3 mt-4 mt-lg-0"
          cols="10"
          lg="4"
          xl="3"
          md="6"
        >
          <support-btn />
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';
import SupportBtn from './SupportBtn.vue';

export default {
  components: {
    I18n,
    SupportBtn,
  },
};
</script>

<style lang="scss">
#f-header {
  a:hover, a:focus {
    text-decoration: none;
  }

  h1 {
    font-size: 2.5rem;
    @media (min-width: 576px) {
      font-size: 3.5rem;
    }

    img {
      width: 96px;
      height: 96px;
    }

    .violet, .orange { color: var(--f-f1) !important; }
  }

  .dropdown-item {
    background-image: none;
  }
}

#f-menu {
  background: transparent;

  > li > a,
  button {
    color: var(--f-f1);
    font-weight: bold;
    font-size: 1.25rem;
  }

  button i::before {
    color: var(--f-f1);
  }

  button:hover { background: none; }
}
</style>
