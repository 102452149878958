<template>
  <div>
    <b-container
      id="f-page"
      tag="main"
    >
      <b-row align-v="center">
        <h1 class="col-12 text-center">
          <span v-html="$t('educ-pop.title')"></span>
        </h1>
        <b-col lg="8">
          <div v-html="$t('educ-pop.intro')"></div>
        </b-col>
      </b-row>

      <b-row
        v-for="row in Object.keys(rows)"
        :key="row"
        class="my-4"
      >
        <h2
          class="col-12"
          v-html="$t(`educ-pop.${row}.title`)"
        ></h2>
        <b-col
          v-for="card in rows[row]"
          :key="card"
          class="mx-auto ml-xl-0"
          md="12"
          lg="8"
          xl="6"
        >
          <b-row align-v="center">
            <b-col sm="6">
              <f-card :card="card" />
            </b-col>
            <b-col sm="6">
              <div
                v-html="$t(`educ-pop.${row}.${card}`)"
              ></div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <f-news v-if="/^fr/.test(this.$t('lang'))" />
  </div>
</template>

<script>
import FCard from '../components/FCard.vue';
import FNews from '../components/FNews.vue';

export default {
  components: {
    FCard,
    FNews,
  },

  data() {
    return {
      rows: {
        experience: ['conf', 'blog', 'interview'],
        knowledge: ['upload', 'dlec', 'mooc-chatons'],
        spread: ['dottube', 'docs', 'lang'],
        misc: ['mednum', 'teleworking', 'resolu'],
      },
    };
  },
};
</script>

