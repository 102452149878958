<template>
  <section class="mt-2 d-flex flex-column">
    <div
      id="c2022-frama"
      class="mt-5"
    >
      <b-container class="p-4 px-sm-0">
        <h2
          class="quack"
          style="display: none;"
          v-html="$t('c2022.frama.ctitle')"
        ></h2>
        <h2 v-html="$t('c2022.frama.title')"></h2>
        <div
          class="col-lg-9 p-0 mb-n1 "
          v-html="$t('c2022.frama.desc')"
        ></div>
      </b-container>
    </div>

    <div
      v-for="section in Object.keys(sections)"
      :id="section"
      :key="section"
    >
      <b-container class="p-4 px-sm-0">
        <b-row class="cards mx-0">
          <b-col
            v-for="key in sections[section].cards"
            :key="key"
            lg="3"
            md="6"
            cols="12"
            class="d-flex justify-content-center p-0"
          >
            <div
              :id="`flip-card-${key}`"
              :ref="`flip-card-${key}`"
              class="flip-card"
            >
              <div class="inner">
                <div class="front">
                  <div
                    class="layer-bg-img"
                  >
                    <div
                      :style="`background-image: url('${$t('baseurl')
                      }img/campaign/2022/${key}.jpg');`"
                    ></div>
                  </div>

                  <div class="layer-frame"></div>

                  <button
                    class="layer-content"
                    :title="$t('c2022.flip', {
                      name: $t(`c2022.cards.${key}.name`)
                    })"
                    @click="flipCard(`flip-card-${key}`)"
                  >
                    <h3 v-html="$t(`c2022.cards.${key}.name`)"></h3>
                  </button>
                </div>

                <div
                  class="back"
                  @click="flipCard(`flip-card-${key}`)"
                >
                  <h3
                    aria-hidden="true"
                    v-html="$t(`c2022.cards.${key}.name`)"
                  ></h3>
                  <div
                    class="desc"
                    v-html="$t(`c2022.cards.${key}.desc`)"
                  ></div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sections: {
        highlight: {
          cards: ['dio', 'peertube', 'chatons', 'mobilizon', 'misc', 'dlec', 'upload', 'blog'],
        },
      },
    };
  },

  created() {
    document.body.classList.add('campaign');
  },

  methods: {
    flipCard(id) {
      if (this.$refs[id][0].classList.contains('showback')) {
        this.$refs[id][0].classList.remove('showback');
        setTimeout(() => {
          this.$refs[id][0].classList.remove('inactive');
        }, 1000);
      } else {
        this.$refs[id][0].classList.add('showback');
      }

      const cards = Object.keys(this.$refs).filter(item => item !== id);
      cards.forEach((key) => {
        if (this.$refs[key][0]) {
          this.$refs[key][0].classList.remove('showback');
          if (this.$refs[id][0].classList.contains('showback')) {
            setTimeout(() => {
              this.$refs[id][0].classList.remove('inactive');
            }, 1000);
          }
        }
      });
    },

    flipAll() {
      const cards = Object.keys(this.$refs);
      cards.forEach((key) => {
        if (this.$refs[key][0]) {
          this.$refs[key][0].classList.toggle('showback');
          setTimeout(() => {
            this.$refs[key][0].classList.remove('inactive');
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="scss">
#roadmap.campaign {
  #f-header {
    margin-bottom: 1rem;
  }

  .home {
    background: var(--f-g3);
    position: relative;
  }

  #f-sfs {
    background: var(--f-g3) !important;

    #support {
      .col-lg-7 .ombre {
        margin-top: 3rem !important;
      }
    }
  }

  .sticky-top .btn-outline-secondary {
    background-color: var(--f-g2);
    border-color: transparent;

    &:hover, &:focus {
      background: var(--f-g7);
    }
  }

  /* Cards */
  .cards {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    h3 {
      margin-top: 0px;
      margin-bottom: 12px;
      font-size:20px;
      font-weight: bold;
      text-align: center;

      &::after {
        content: none !important;
      }
    }

    .desc { // units in px (cards size is fixed)
      font-size: 15px;
      line-height: 21px;
      margin-top: 12px;
    }
  }

  .flip-card {
    background-color: transparent;
    width: 235px;
    height: 380px;
    perspective: 1000px;
    padding: 20px 0; /* keep shadow & card rotation visible */

    &.inactive .front {
      &::before {
        background: linear-gradient(to bottom, var(--f-g6), var(--f-g7));
        filter: grayscale(.85);
      }

      .layer-bg-img, .layer-frame {
        filter: grayscale(.75);
      }
    }

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    // &:focus-within .inner,
    &.showback .inner {
      transform: rotateY(180deg);
    }

    .front, .back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
      box-shadow: 0px 9px 15px -10px rgba(0, 0, 0, 0.7);
      border-radius: 4px;
    }

    .front {
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, var(--f-f7), var(--f-f8));
        border-radius: 4px;
      }

      > * {
        position: absolute;
      }

      .layer-bg-img,
      .layer-frame,
      .layer-bg-btn,
      .layer-content {
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        margin: 0;
        width: 100%;
        height: 100%;
      }

      .layer-bg-img {
        width: calc(100% - 30px);
        height: calc(100% - 145px);
        margin: 21px 15px;
        z-index: 0;
        transition: transform .5s ease-in-out;
        > div {
          border-radius: 20px 20px 0 0;
          width: 100%;
          height: 100%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      &:hover {
        .layer-bg-img {
          transform:scale(1.05)
        }
      }

      .layer-frame {
        background-image: url('../../../public/img/campaign/2022/card-frame.png');
        z-index: 1;
      }

      .layer-bg-btn {
        z-index: 2;
      }

      .layer-content {
        display: flex;
        padding: 0;
        z-index: 3;

        h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--f-f9);
          min-height: 74px;
          width: 100%;
          margin-bottom: 35px;
          margin-top: auto;
          padding: 0 30px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }

    .back {
      background:
        var(--f-g4)
        url('../../../public/img/campaign/2022/back.jpg')
        repeat
        center center;
      border: 6px solid var(--f-f6);
      color: var(--f-g7);
      cursor: pointer;
      overflow: hidden;
      padding: 1rem .75rem;
      transform: rotateY(180deg);
      z-index: 6;

      h3 {
        margin-bottom: 0;
        color: var(--f-f8);
      }
    }
  }
}

#progress-banner {
  display: block;
  order: 6;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 1rem;

  .banner {
    border: 5px solid var(--f-g7);
    border-left: none;
    border-right: none;
    position: relative;
    height: 240px;
    width: 100%;

    @media (min-width: 768px) {
      height: 420px;
    }
  }

  .active, .inactive {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    div {
      width: 100vw;
      height: 100%;
      background-image: url('../../../public/img/campaign/2022/banner.jpg');
      background-size: cover;
      background-position: right;
    }
  }

  .inactive {
    filter: grayscale(.85);
  }

  .active {
    border-right: 2px solid var(--f-g7);
    box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.7);
    transition: width .1s linear;
    z-index: 1;
  }

  .animate {
    transition: width 1s ease-in-out;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    display: block;
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 3px;
    outline: none;
    width: calc(100% + 24px);
    z-index: 2;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background: transparent;
      border: 12px solid var(--f-g7);
      border-top: 0 solid transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &::-moz-range-thumb {
      background: transparent;
      border: 12px solid var(--f-g7);
      border-top: 0 solid transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}

@keyframes card-glow {
  0% {
    filter: drop-shadow(0 0 3px currentColor);
  }
  50% {
    filter: drop-shadow(0 0 13px currentColor);
  }
}
</style>
